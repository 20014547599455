import http from '@/utils/http';

export const GameMatchInfo = (parameter) => {
  return http.request({
    url: '/v1/games/match/info',
    method: 'get',
    params: parameter
  })
}

export const GameMatchSearch = (parameter) => {
  return http.request({
    url: '/v1/games/match/search',
    method: 'get',
    params: parameter
  })
}

export const UpdateMatch = (parameter) => {
  return http.request({
    url: '/v1/games/match/update',
    method: 'post',
    data: parameter
  })
}

export const GameMatchList = (parameter) => {
  return http.request({
    url: '/v1/games/match/list',
    method: 'get',
    params: parameter
  })
}

export const editMatch = (parameter) => {
  return http.request({
    url: '/v1/games/match/edit',
    method: 'post',
    data: parameter
  })
}

// 搜索比赛
export const searchMatch = (params) => {
  return http.request({
      url: "/v1/games/match/search",
      method: 'post',
      data: params,
  })
}

// 主客反转 设置
export const setMatchTeamRev = (params) => {
  return http.request({
      url: "/v1/games/match/team_rev/set",
      method: 'post',
      data: params,
  })
}
export const delMatchTeamRev = (params) => {
  return http.request({
      url: "/v1/games/match/team_rev/del",
      method: 'post',
      data: params,
  })
}

export const updateEsSource = (params) => {
  return http.request({
      url: "/v1/games/match/es_source/update",
      method: 'post',
      data: params,
  })
}

// 修复
export const FixMatch = (parameter) => {
  return http.request({
    url: '/v1/games/match/fix',
    method: 'post',
    data: parameter
  })
}
