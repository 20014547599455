<template>
  <div>
    <Modal
        v-model = "modal_add_relation"
        :title= "String(modal_edit_id) + ' 搜索' "
        @on-ok="addRelation"
    >
        <matchSearch :game_id="game_id" v-model="new_relation" ></matchSearch>
    </Modal>

    <Row style="margin-bottom:10px;" :gutter="16" align="middle">
        <Col span="4" style="text-align: left">
            <Input v-model="search_key" placeholder="搜索" search @on-search="onSearch" />
        </Col>
        <Col span="8" style="text-align: left">
            <DatePicker format="yyyy-MM-dd HH:mm" :options="date_options" v-model="datarange" type="daterange" placement="bottom-end"
                placeholder="日期范围" style="width: 100%" @on-change='onDatarangeChange' editable></DatePicker>
        </Col>
        <Col span="4" style="text-align: left">
            <Select v-model="status_id" @on-change="onStatusIdChange">
                <Option :value="0" :key="0">全部状态</Option>
                <Option v-for="item in $conf.games.status.map" :value="item.id" :key="item.id">{{item.name}}</Option>
            </Select>
        </Col>
        <Col span="4" style="text-align: left">
            <Select v-model="source_id" @on-change="onSourceIdChange">
                <Option v-for="item in sources" :value="item.id" :key="item.id">{{item.name}}</Option>
            </Select>
        </Col>
        <Col span="4" style="text-align: left">
            <Checkbox v-model="filter" border>未关联</Checkbox>
        </Col>
    </Row>
    <fitTable
        :columns="columns" 
        :data="data" 
        :loading="loading" 
    >
    </fitTable>
    <Page
        :total="total_page"
        :current="current_page"
        :page-size="page_size"
        @on-change="onPageChange"
        show-total
        show-elevator
    ></Page>
  </div>
</template>
<script>

import { getSources } from '@/api/relation/games/source';
import { updateDetail, deleteDetail, updateDetailSourceTeamrev } from '@/api/relation/games/detail';
import { getMatchs } from '@/api/relation/games/match';
import fitTable from '../../../basic/table.vue';
import matchSearch from '@/components/games/match/search.vue';
import moment from 'moment';

export default {
    name: "relation-match-list",
    props: {
        game_id: Number,   // 运动id
    },
    components: {
        fitTable,
        matchSearch,
    },
    data () {
        let self = (window.x = this)
        return {
            filter: false,
            tid: 0,
            source_id: 0, 
            sources: {},
            modal_add_relation: false,
            modal_edit_id: 0,
            new_relation: { id:0, home:{}, away:{}, tournament:{} },

            start_date: parseInt( new Date(new Date().toLocaleDateString()).valueOf() * 0.001 ),
            end_date  : parseInt( new Date(new Date().toLocaleDateString()).valueOf() * 0.001 ),
            datarange : [new Date(new Date().toLocaleDateString()),new Date(new Date().toLocaleDateString())],
            date_options: {
                shortcuts: [
                    {
                        text: '1个月',
                        value () {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
                            return [start, end];
                        }
                    },
                    {
                        text: '1周',
                        value () {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
                            return [start, end];
                        }
                    },
                    {
                        text: '6小时',
                        value () {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 6);
                            return [start, end];
                        }
                    },
                    {
                        text: '3小时',
                        value () {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 3);
                            return [start, end];
                        }
                    },
                    {
                        text: '30分钟',
                        value () {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 1800 * 1000);
                            return [start, end];
                        }
                    },
                    {
                        text: '昨天',
                        value () {
                            const end = new Date(new Date().toLocaleDateString());
                            const start = new Date();
                            start.setTime(end.getTime() - 3600 * 1000 * 24);
                            return [start, end];
                        }
                    },
                    {
                        text: '今天',
                        value () {
                            const end = new Date();
                            const start = new Date(new Date().toLocaleDateString())
                            end.setTime(start.getTime() + 3600 * 1000 * 24);
                            return [start, end];
                        }
                    },
                    {
                        text: '明天',
                        value () {
                            const end = new Date();
                            const start = new Date(new Date().toLocaleDateString())
                            start.setTime(start.getTime() + 3600 * 1000 * 24);
                            end.setTime(start.getTime() + 3600 * 1000 * 24 * 1);
                            return [start, end];
                        }
                    },
                    
                ]
            },
            status_id: 0,

            search_key: '',
            loading: true,
            total_page: 0,
            page_size: 0,
            current_page: 1,
            columns: [
                {
                    title: 'id',
                    align: 'center',
                    key: 'id',
                    width: 80,
                },
                {
                    title: '赛事',
                    align: 'center',
                    key: 'tournament_id',
                    minWidth: 80,
                    render: function (h, params) {
                        let currentRow = params.row
                        var tournament = currentRow.tournament
                        return tournament.id > 0? [
                            h('span', {class: 'text-link', }, tournament.id + ' ',  ),
                            h('span', {class: 'text-main', }, tournament.name,  )
                        ]: ''
                    }
                },
                {
                    title: '主队',
                    key: 'home_team_id',
                    align: 'center',
                    minWidth: 80,
                    render: function (h, params) {
                        let currentRow = params.row;
                        let home = currentRow.home;
                        let resp = [];
                        resp.push(h("Row", {attrs: {justify: 'center' }}, 
                                    [h('img', { attrs: { src: self.$unit.getImgGameUrl(self.game_id, 'team', home.logo), shape: 'square', size: 'middle' },
                                        class: {'logo': true} })],
                                ));
                        resp.push(h("Row", {attrs: {justify: 'center' }}, 
                                    [
                                        h('span', {class: 'text-link',}, home.id + ' ',  ),
                                        h('span', {class: 'text-main',}, home.name,  )
                                    ],
                                ))
                        return h(
                            'div', resp.length > 0 ? resp: ''
                        )
                    }
                },
                {
                    title: '客队',
                    key: 'away_team_id',
                    align: 'center',
                    minWidth: 80,
                    render: function (h, params) {
                        let currentRow = params.row
                        let away = currentRow.away;
                        let resp = [];
                        
                        resp.push(h("Row", {attrs: {justify: 'center',  }, }, 
                                    [h('img', { attrs: { src:  self.$unit.getImgGameUrl(self.game_id, 'team', away.logo), shape: 'square', size: 'middle' },
                                        class: {'logo': true} })],
                                ));
                        resp.push(h("Row", {attrs: {justify: 'center' }}, 
                                    [
                                        h('span', {class: 'text-link', }, away.id + ' ',  ),
                                        h('span', {class: 'text-main', }, away.name, )
                                    ],
                                ))
                        return h(
                            'div', resp.length > 0 ? resp: ''
                        )
                    }
                },
                {
                    title: '比赛时间',
                    width: 100,
                    align: 'center',
                    key: 'match_time',
                    render: function (h, params) {
                        let currentRow = params.row;
                        return h( 'span', moment.unix(currentRow.match_time).format('YYYY-MM-DD HH:mm') )
                    }
                },
                {
                    title: 'Bo',
                    align: 'center',
                    key: 'box',
                    minWidth: 30,
                },
                // 
                {
                    title: '状态',
                    minWidth: 60,
                    align: 'center',
                    key: 'status_id',
                    render: function (h, params) {
                        let currentRow = params.row;
                        let status = self.$conf.games.status.map[currentRow.status_id] || {};
                        return h( 'span', status.name_zh || '' );
                    }
                },
                {
                    title: '关联线上',
                    align: 'left',
                    key: 'relation',
                    minWidth: 80,
                    render: function (h, params) {
                        let currentRow = params.row;
                        let relation = currentRow.relation ;
                        if (relation && relation.id > 0){
                            return [
                                h("row", [
                                    h('Poptip', 
                                        {
                                            props: {
                                                placement: 'left-start',
                                                confirm: true,
                                                transfer: true,
                                                title: '确定删除关联？',
                                            },
                                            on: {
                                                'on-ok': () => {
                                                    self.delRelation(params.row.id, relation.relation_id);
                                                },
                                                'on-cancel': () => {
                                                }
                                            }
                                        }, 
                                        [h('Icon', 
                                            {   
                                                attrs: { 
                                                    type: "md-trash",
                                                    color: 'red' ,
                                                    size: 16,
                                                }, 
                                            },  
                                        )] 
                                    ),
                                    h('span', 
                                        {   
                                            class: "text-link", 
                                        }, 
                                        relation.id ,
                                    ),
                                ]),
                                h("row", [
                                    h('span', 
                                        {   
                                            class: "text-main", 
                                        }, 
                                        "主客反转:",
                                    ),
                                    h('i-switch', { //数据库1是已处理，0是未处理
                                        props: {
                                            type: 'primary',
                                            value: relation.teamrev === 1 //控制开关的打开或关闭状态，官网文档属性是value
                                        },
                                        style: {
                                            // marginRight: '5px'
                                        },
                                        on: {
                                            'on-change': (value) => { //触发事件是on-change,用双引号括起来，
                                                //参数value是回调值，并没有使用到
                                                // this.switch(params.index) //params.index是拿到table的行序列，可以取到对应的表格值
                                                // console.log(self.game_id, currentRow.id, value)
                                                if (value){
                                                    self.setTeamrev(relation.relation_id, 1);
                                                }else{
                                                    self.setTeamrev(relation.relation_id, 0);
                                                }
                                            },
                                        }
                                    })
                                ])
                            ]
                        }else{
                            return [ h("Row", 
                                [h('Col', { attrs: { span: 24 }}, 
                                    [h('Icon', 
                                        {   
                                            attrs: { 
                                                type: "md-add-circle", 
                                                color: 'green' ,
                                                size: 16,
                                            }, 
                                            on: {
                                                'click':() => {
                                                    // 新增关联
                                                    self.modal_add_relation = true;
                                                    self.modal_edit_id = params.row.id;
                                                    self.new_relation = {id:0, home:{}, away:{}, tournament:{} };
                                                }
                                            } 
                                        },  
                                    )] 
                                )]
                            )]
                        }
                    }
                },
                {
                    title: '关联主队',
                    minWidth: 80,
                    align: 'center',
                    key: 'relation.home',
                    render: function (h, params) {
                        let currentRow = params.row;
                        let relation = currentRow.relation ;
                        if (relation)
                        return [h("Row", {attrs: {justify: 'center' }}, 
                                                [h('img', { attrs: { src: self.$unit.getImgGameUrl(self.game_id, 'team', relation.home.logo), shape: 'square', size: 'middle' },
                                                    class: {'logo': true} })],
                                            ),
                            h("Row", {attrs: {justify: 'center' }}, 
                                        [
                                            h('span', {class: 'text-link',}, relation.home.id + ' ',  ),
                                            h('span', {class: 'text-main',}, relation.home.name,  )
                                        ],
                            )]
                    }
                },
                {
                    title: '关联客队',
                    minWidth: 80,
                    align: 'center',
                    key: 'relation.away',
                    render: function (h, params) {
                        let currentRow = params.row;
                        let relation = currentRow.relation ;
                        if (relation)
                        return [h("Row", {attrs: {justify: 'center' }}, 
                                                [h('img', { attrs: { src: self.$unit.getImgGameUrl(self.game_id, 'team', relation.away.logo), shape: 'square', size: 'middle' },
                                                    class: {'logo': true} })],
                                            ),
                            h("Row", {attrs: {justify: 'center' }}, 
                                        [
                                            h('span', {class: 'text-link',}, relation.away.id + ' ',  ),
                                            h('span', {class: 'text-main',}, relation.away.name,  )
                                        ],
                            )]
                    }
                },
                {
                    title: '比赛时间',
                    width: 100,
                    align: 'center',
                    key: 'relation.match_time',
                    render: function (h, params) {
                        let currentRow = params.row;
                        let relation = currentRow.relation ;
                        if (relation)
                        return h( 'span', moment.unix(relation.match_time).format('YYYY-MM-DD HH:mm') )
                    }
                },
            ],
            data: [],
        }
    },
    methods: {
        onStatusIdChange(value){
            let self = this;
            self.current_page = 1;
            self.getData();
        },
        onDatarangeChange(value){
            // console.log("5 change", value);
            let self = this;
            self.current_page = 1;
            if (value.length > 0){
                self.start_date = parseInt( Date.parse(value[0]) * 0.001 );
                self.end_date   = parseInt( Date.parse(value[1]) * 0.001 );
                // console.log( typeof( self.start_date ), self.start_date );
            }else{
                self.start_date = 0;
                self.end_date = 0;
            }
            self.start_date = self.start_date || 0;
            self.end_date   = self.end_date || 0;
            self.getData();
        },
        // end
        onPageChange (page) {
            this.current_page = page
            this.getData()
        },
        onSearch (search_key) {
            this.current_page = 1;
            this.search_key = search_key;
            this.getData();
        },
        onSourceIdChange(value){
            let self = this;
            self.current_page = 1;
            self.getData();
        },
        // 设置住客反转
        setTeamrev(relation_id, teamrev){
            let self = this;
            updateDetailSourceTeamrev({
                relation_id,
                teamrev
            }).then(response => {
                if (response.data.code == 0) {
                    self.$Message.success('成功');
                } else {
                    self.$Message.error(response.data.msg);
                }
            })
        },
        // end
        getSourceData () {
            let self = this;
            var params = {
                game_id: self.game_id,
            }
            getSources(params).then(response => {
                if (response.data.code == 0) {
                    let sources = response.data.data.list || [];
                    sources.forEach(element => {
                        self.sources[element.id] = element;
                        if (self.source_id == 0){
                            self.source_id = element.id;
                        }
                    });
                    self.getData();
                } else {
                    this.$Message.error(response.data.msg);
                }
            })
        },
        // end
        getData () {
            let self = this;
            this.loading = true;
            
            if (self.end_date > 0 && self.end_date === self.start_date){
                self.end_date = self.end_date + 3600 * 24 - 1;
            } 

            var params = {
                game_id: self.game_id,
                search_key: self.search_key,
                page: self.current_page,
                source_id: self.source_id,
                filter: self.filter ? 1 : 0,
                start_time: self.start_date,
                end_time  : self.end_date,
                status_id : self.status_id,
            }
            getMatchs(params).then(response => {
                // console.log(response)
                if (response.data.code == 0) {
                    this.data = response.data.data.list;
                    this.total_page = response.data.data.total_page;
                    this.page_size = response.data.data.page_size;
                    this.current_page = response.data.data.current_page;
                    this.tid = response.data.data.tid;
                    this.loading = false;
                } else {
                    this.$Message.error(response.data.msg);
                }
            })
        },
        // end
        addRelation(){
            let self = this;
            // console.log(self.tid, self.uid, self.new_relation)
            if(self.tid > 0 && self.modal_edit_id > 0 
                    && self.new_relation && self.new_relation.id > 0){
                let params = {
                    // id: _id,
                    game_id: self.game_id,
                    tid: self.tid,
                    pid: self.source_id,
                    uid: self.modal_edit_id,
                    'data': self.new_relation.id,
                }
                // console.log(params)
                updateDetail(params).then(response => {
                    if (response.data.code == 0) {
                        var relation_id = response.data.data.id;
                        if (relation_id > 0){
                            self.new_relation['relation_id'] = relation_id;
                            self.data.forEach(element => {
                                if (element.id == this.modal_edit_id){
                                    // console.log(element)
                                    self.$set(element, 'relation', self.new_relation)
                                    return
                                }
                            })
                            self.$Message.success('添加成功');
                        }
                        
                    } else {
                        self.$Message.error(response.data.msg);
                    }
                })
            }
        },
        // end
        delRelation(row_id, relation_id){
            let self = this;
            let params = {
                relation_id: relation_id,
            }
            deleteDetail(params).then(response => {
                if (response.data.code == 0) {
                    self.data.forEach(element => {
                        if (element.id == row_id){
                            // console.log(element)
                            self.$delete(element, 'relation')
                            return
                        }
                    })
                } else {
                    self.$Message.error(response.data.msg);
                }
            })
        },
        // end 
    },
    mounted () {
        this.getSourceData();
        // console.log(this.source_id);
        // this.getData();
    },
    watch: {
        filter(value){
            let self = this;
            self.current_page = 1;
            self.getData();
        }
    },
    computed: {}
}
</script>
<style scoped>
    @import "list.css"
</style>