import http from '@/utils/http';


export const getMatchs = (parameter) => {
  return http.request({
    url: '/v1/relation/games/match/list',
    method: 'get',
    params: parameter
  })
}

export const searchMatch = (params) => {
  return http.request({
    url: '/v1/relation/games/match/search',
    method: 'post',
    data: params,
  })
}

// 获取比赛的关联
export const getMatchSourceId = (parameter) => {
  return http.request({
    url: '/v1/relation/games/match/source/get',
    method: 'get',
    params: parameter
  })
}
// 更新比赛的关联
export const setMatchSourceId = (parameter) => {
  return http.request({
    url: '/v1/relation/games/match/source/set',
    method: 'post',
    data  : parameter,
  })
}